// ----------------------------------------
// Common Utilities
// ----------------------------------------

function debounce(func, wait, immediate) {
    let timeout;
    return function() {
        const context = this, args = arguments;
        const later = function() {
            timeout = null;
            if (!immediate) { func.apply(context, args); }
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) { func.apply(context, args); }
    };
}

// Adds an item to the array and returns a copy.
function add(items, newItem, index) {
    return [
        ...items.slice(0, index),
        newItem,
        ...items.slice(index, items.length),
    ];
}

// Removes an item  from an array and returns a copy.
function remove(items, index) {
    return [
        ...items.slice(0, index),
        ...items.slice(index + 1, items.length),
    ];
}

// Moves an item in an array and returns a copy.
function move(items, oldIndex, newIndex) {
    const itemRemovedArray = remove(items, oldIndex);
    return add(itemRemovedArray, items[oldIndex], newIndex);
}

export {
    debounce,
    add,
    remove,
    move,
};
