// ----------------------------------------
// Cards
// ----------------------------------------

import Flickity from 'flickity';

//
// Collapsible Cards
//

for (const elem of document.getElementsByClassName('card-collapsible')) {
    new Flickity(elem, {
        // contain: true,
        watchCSS: true,
        cellAlign: 'center',
        adaptiveHeight: true,
    });
}
