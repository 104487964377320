import $ from 'jquery';

const $searchForm = $('.js-search');
const $searchInput = $searchForm.find('[type="search"]');

$('.js-search-open').click(() => {
    $searchForm.fadeIn(200);
    $searchInput.focus();
});

$searchInput.blur(() => {
    $searchForm.fadeOut(200);
});
