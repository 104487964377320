import $ from 'jquery';
import { debounce } from './utils';

// In order to stop elements that use VH units from changing on
// iOS, their heights are fixed here.
const vhFix = () => {
    $('.js-vh-fix').each(function() {
        // Reset the height to auto.
        $(this).css({ height: '' });

        const viewportHeight = $(this).outerHeight();
        $(this).css({ height: viewportHeight });
    });
};

vhFix();

let windowWidth = $(window).width();
$(window).resize(debounce(() => {
    // Prevent the function from being called if the window width did not change.
    const newWindowWidth = $(window).width();

    if (newWindowWidth !== windowWidth) {
        windowWidth = newWindowWidth;
        vhFix();
    }
}, 200));
