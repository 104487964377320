// ----------------------------------------
// Navigation
// ----------------------------------------

import $ from 'jquery';
import Flickity from 'flickity';

$('.js-submenu').click(function(event) {
    event.preventDefault();

    if ($(this).siblings('.section-header_submenu--wrapper').is(':hidden')) {
        $('.js-submenu').siblings('.section-header_submenu--wrapper').hide();
        $('.js-submenu').removeClass('section-header_menu_item_link--open');

        $(this).siblings('.section-header_submenu--wrapper').show();
        $(this).addClass('section-header_menu_item_link--open');
    } else {
        $(this).siblings('.section-header_submenu--wrapper').hide();
    }

    return false;
});


// Mobile menu

const mobileCarousel = new Flickity('#mobile-nav-carousel', {
    contain: true,
    draggable: false,
    adaptiveHeight: true,
    prevNextButtons: false,
    pageDots: false,
});

const $mobileMenu = $('.js-mobile-menu');
$('#menu-open').click(function() {
    $(this).toggleClass('js-active');

    // Execute a slide toggle and force a reflow of the carousel,
    // or it won't have a height.
    $mobileMenu.slideToggle({
        duration: 300,
        progress() {
            mobileCarousel.resize();
        },
    });
});

// When you click on a mobile link with descendants,
// the slider will advance 1 slide and show the submenu.
$('.js-mobile-link').click(function(event) {
    event.preventDefault();

    // Get the ID of the node.
    const nodeId = $(this).data('node');

    // Hide all currently shown submenus.
    const $items = $('.js-mobile-submenu[data-level="1"]').hide();

    // Show the submenu with the associated node ID.
    $items.filter(`[data-parent="${nodeId}"]`).show();

    // Show the slide.
    mobileCarousel.resize();
    mobileCarousel.select(1);

    return false;
});

$('#mobile-nav-back').click(() => {
    mobileCarousel.select(0);
});
