// ----------------------------------------
// Tabs
// ----------------------------------------

import $ from 'jquery';
$('.tabs .tabs_nav_link').click(function(event) {
    event.preventDefault();

    const $tabs = $(this).closest('.tabs');

    const id = $(this).attr('href');
    const $tab = $tabs.find(id);

    $tabs.find('.tabs_nav_link.js-active').removeClass('js-active');
    $tabs.find('.tabs_content_tab.js-active').removeClass('js-active');

    $(this).addClass('js-active');
    $tab.addClass('js-active');

    return false;
});
