// ----------------------------------------
// Interactive map
// ----------------------------------------

import $ from 'jquery';
import { debounce } from '../utils/utils';

const $mapSvg = $('#map-svg');
const $mapLineWrap = $('#map-line');
const $mapLine = $mapLineWrap.find('line');
const $mapCircle = $mapLineWrap.find('circle');
const $mapDetails = $('#map-details');

const firstRegion = $mapSvg.data('first-region');
let $activeElement = $mapSvg.find(`[data-region="${firstRegion}"]`).addClass('js-active');

$('#map-svg .map-region').mouseover(testElementOver).mouseleave(testElementOut).each(function() {
    const region = $(this).data('region');

    let href = $mapDetails.find(`[data-region="${region}"]`).attr('href');
    if (!href) {
        href = $mapDetails.find(`[data-region="${region}"] a[href]`).attr('href');
    }

    $(this).parent().attr('href', href);
});

$('#map-svg .map-poi').mouseover(testElementOver).mouseleave(testElementOut).each(function() {
    const poi = $(this).data('poi');

    let href = $mapDetails.find(`[data-poi="${poi}"]`).attr('href');
    if (!href) {
        href = $mapDetails.find(`[data-poi="${poi}"] a[href]`).attr('href');
    }

    $(this).parent().attr('href', href);
});

$(window).resize(debounce(() => {
    moveLine();
}, 800));

function testElementOver() {
    const _this = this;

    $(_this).data('hover-timeout', setTimeout(() => {
        focusElement(_this);
    }, 250));
}

function testElementOut() {
    const timeout = $(this).data('hover-timeout');
    clearTimeout(timeout);
}

function focusElement(element) {
    const $this = $(element);

    const region = $this.data('region');
    const poi = $this.data('poi');

    $mapDetails.find('[data-region], [data-poi]').removeClass('js-active');

    if (region) {
        $mapDetails.find(`[data-region="${region}"]`).addClass('js-active');
    }

    if (poi) {
        $mapDetails.find(`[data-poi="${poi}"]`).addClass('js-active');
    }

    $mapSvg.find('.map-region, .map-poi').removeClass('js-active');
    $this.addClass('js-active');

    $activeElement = $this;

    moveLine();
}

function moveLine() {
    if ($activeElement.length === 0) {
        return;
    }

    const mapOffset = $mapLineWrap.offset();
    const detailsOffset = $mapDetails.position();
    const itemOffset = $activeElement.get(0).getBoundingClientRect();

    const html = document.documentElement;

    let x = (itemOffset.left + itemOffset.width / 2) + window.pageXOffset - html.clientLeft - mapOffset.left;
    let y = (itemOffset.top + itemOffset.height / 2) + window.pageYOffset - html.clientTop - mapOffset.top;

    // Move the line slightly so it's not so close to Derby.
    if ($activeElement.data('region') === 'north-east') {
        x /= 1.02;
        y /= 0.98;
    }

    $mapLine.attr('x1', detailsOffset.left + $mapDetails.width());
    $mapLine.attr('y1', detailsOffset.top + $mapDetails.height() / 2);

    $mapLine.attr('x2', x);
    $mapLine.attr('y2', y);

    $mapCircle.attr('cx', x);
    $mapCircle.attr('cy', y);
}

moveLine();
